@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: A1Sans;
  src: url('./fonts/A1Sans-Regular.woff2') format('woff2');
  font-weight: normal;
}

@font-face {
  font-family: A1Sans;
  src: url('./fonts/A1Sans-Bold.woff2') format('woff2');
  font-weight: bold;
}

@font-face {
  font-family: A1Serif;
  src: url('./fonts/A1Serif-Regular.woff2') format('woff2');
  font-weight: normal;
}



@layer base {
  h1 {
    @apply text-4xl text-black font-normal;
    font-family: 'A1Serif', serif;
  }

  h2 {
    @apply text-xl text-black font-bold leading-7 font-sans;
    font-family: 'A1Sans', sans-serif;
  }

  p {
    // + text-zinc-700
    @apply text-base font-normal leading-normal;
    font-family: 'A1Sans', sans-serif;
  }

  // forms overrides
  [type='checkbox'],
  [type='radio'] {
    @apply text-primary
  }

  [type='checkbox']:focus,
  [type='radio']:focus {
    --tw-ring-color: 'transparent'
  }

}
